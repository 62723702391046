<template>
  <div class="password">
		<h3>Password</h3>
  </div>
  <div class="password_body">

	<div class="password_form" :class="!change ? 'password_form_disabled' : null">
		<div>
			<Toggle :class="!change ? 'new_toggle' : null" v-model="change"/>Change Password
		</div>
	  <div v-if="false">
			<div>Current Password</div>
			<div>
        <div class="caps_caution" v-if="caps">Caps lock is on</div>
				<input :type="revealOne ? 'text' : 'password'" v-model="oldPass" @keydown="clear" onkeypress="return event.charCode != 32" :disabled="!change" @paste="onPaste">
				<span v-if="change" class="eye" @click="revealOne = !revealOne">
          <span v-if="revealOne"></span>
        </span>
				<span v-if="oldPassDontMatch">{{ errMsg }}</span>
			</div>
	  </div>

	  <div>
			<div>New Password</div>
			<div>
				<input :type="revealTwo ? 'text' : 'password'" v-model="pass" @keydown="clear" :style="err ? {borderColor: 'red'} : null" onkeypress="return event.charCode != 32" :disabled="!change" @paste="onPaste">
				<span v-if="change" class="eye" @click="revealTwo = !revealTwo">
          <span v-if="revealTwo"></span>
        </span>
				<span v-if="err">{{ errMsg }}</span>
				<stages class="stages" :complexity="complexity"></stages>
			</div>
	  </div>

	  <div>
			<div>Repeat Password</div>
			<div>
				<input :type="revealThree ? 'text' : 'password'" v-model="confirm" @keydown="clear" :class="err" :style="dontMatch ? {borderColor: 'red'} : null" onkeypress="return event.charCode != 32" :disabled="!change" @paste="onPaste">
				<span v-if="change" class="eye" @click="revealThree = !revealThree">
          <span v-if="revealThree"></span>
        </span>
				<span v-if="dontMatch">{{ errMsg }}</span>
			</div>
	  </div>

	  <div class="button" v-if="change" @click="changePass">Change Password</div>
	</div>

  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
import stages from '../password/password.vue';
import '../../../src/assets/default.scss';
import Toggle from '@vueform/toggle';
import { SuperTokensController as STC } from '@/services/SuperTokensController.js';

let complexity = ref(0);
let oldPass = ref('temp value until ST update to ver.17+');
let pass = ref('');
let confirm = ref('');
let err = ref(false);
let change = ref(false);
let dontMatch = ref(false);
let oldPassDontMatch = ref(false);
let errMsg = ref('');
let revealOne = ref(false);
let revealTwo = ref(false);
let revealThree = ref(false);
let caps = ref(false);

// let passState = reactive({
//   revealed: false,
//   fieldType: 'password',
// });

// const passToggle = () => {
//   passState.revealed = !passState.revealed;
//   passState.fieldType = passState.fieldType === 'password' ? 'text' : 'password';
// };

function changePass() {
	if (pass.value.length >= 8) {
		if (pass.value === confirm.value) {
			store.commit('savingPage');
      STC.changeCurrentUserPassword(oldPass.value,pass.value).then((response) => {
        if (response.result) {
          // console.log('Success');
          // alert('Success');
          change.value = false;
          revealOne.value = false;
          revealTwo.value = false;
          revealThree.value = false;
          complexity.value = 0;
          pass.value = '';
          confirm.value = '';
          clear();
        } else {
          err.value = true;
          errMsg.value = "Passwords don't match";
        }
			});
			store.commit('pageSaved');
		} else { 
			dontMatch.value = true;
			errMsg.value = "Passwords don't match";
		}
	} else {
		err.value = true;
		errMsg.value = 'Need stronger password';
	}
}

function clear() {
	err.value = false;
	dontMatch.value = false;
	oldPassDontMatch.value = false;
	errMsg.value = ''
}
document.addEventListener('keyup', (e) => {
  if (e.getModifierState('CapsLock')) {
    caps.value = true;
  } else {
    caps.value = false;
  }
});
// !       !
function onPaste(e) {
	e.preventDefault();
	let pastedText = (e.clipboardData || window.clipboardData).getData('text');
	pastedText = pastedText.split(' ').join('');
	e.clipboardData.setData('text/plain', pastedText);
	document.execCommand("insertHTML", false, pastedText);
};

function watchPass() {
	let hasNumber = /\d/;
	let uppercase = /[A-Z]/;
	let character = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

	if (pass.value.length >= 9 && hasNumber.test(pass.value) && uppercase.test(pass.value) && character.test(pass.value)) {
		complexity.value = 4;
	} else if(pass.value.length >= 8 && hasNumber.test(pass.value) && uppercase.test(pass.value) || pass.value.length >= 8 && hasNumber.test(pass.value) && character.test(pass.value) || pass.value.length >= 8 && uppercase.test(pass.value) && character.test(pass.value)) {
		complexity.value = 3;
	} else if(pass.value.length >= 8) {
		complexity.value = 2;
	} else if(pass.value.length >= 1 && pass.value.length <= 8 && !uppercase.test(pass.value) && !character.test(pass.value)) {
		complexity.value = 1;
	} else if(pass.value == '') {
		complexity.value = 0;
	}
};

watch(pass, () =>
	watchPass(), {
	immediate: true
});
</script>

<style lang="scss" scoped>
.password{
  h3{
		height: 60px;
		line-height: 60px;
		margin: 0px;
		padding: 0 20px 0 20px;
		background-color: #f2f2f2;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
  }
}
.password_body{
  padding: 0 30px;
  .password_form{
		border: 1px solid #bcbcbc;
		margin-top: 16px;
		padding: 30px;
		max-width: fit-content;
		border-radius: 16px;
		>div:first-child{
			display: flex;
			align-content: center;
		}
		>div:not(:first-child){
			margin-top: 30px;
			display: flex;
    	flex-flow: wrap;
			position: relative;
			>div:first-child{
				width: 210px;
				display: flex;
				align-items: center;
			}
			>div:last-child{
				width: 400px;
				display: flex;
				flex-direction: column;
			}
			
        .caps_caution{
          position: absolute;
          color: #1467d0;
          font-size: .75rem;
          line-height: .82rem;
          top: -1rem;
          font-weight: 500;
          right: 0;
        }
        .caps_caution::before{
          display: inline-block;
          content: 'i';
          color: #fff;
          background-color: #1467d0;
          height: .8rem;
          width: .8rem;
          line-height: .9rem;
          font-size: .6rem;
          border-radius: 50%;
          font-style: italic;
          position: absolute;
          left: -.9rem;
          top: -1px;
          text-align: center;
          text-indent: -2px;
        }
			.eye {
        display: inline-block;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 5px;
        top: 10px;
        background-image: url(../../../public/img/password.png);
        background-position: center;
        background-repeat: no-repeat;
				cursor: pointer;
        span {
          display: inline-block;
          height: 27px;
          width: 2px;
          background-color: #787878;
          transform: rotate(60deg);
          position: relative;
          top: 7px;
          right: -19px;
        }
      }
			input{
				height: 60px;
				line-height: 58px;
				box-sizing: border-box;
				outline: none;
				border: 1px solid #BCBCBC;
				border-radius: 8px;
				padding: 0 0 0 8px;
				flex-grow: 1;
				font-size: 1rem;
				color: #252525;
				font-family: 'Inter', sans-serif;
				width: 100%;
				flex-shrink: 0;
			}
			.stages{
				width: 400px;
				flex-shrink: 0;
				margin-top: 16px;
			}
			span{
				flex-grow: 0;
				flex-basis: 50%;
				// padding-left: 310px;
				font-size: 0.75rem;
				color: red;
			}
		}
		.button{
			justify-content: center;
			padding: 0 24px;
			margin-top: 16px;
			text-transform: capitalize;
			width: 100%;
			height: 60px;
			line-height: 60px;
			font-weight: 400;
		}
  }
	.password_form_disabled{
    background-color: #e9e9e9;
    border: 1px solid #e9e9e9;
      .toggler_field{
        .toggle-off{
          background: #d5d5d5;
        }
      }
      input{
        background-color: transparent;
        cursor: default;
      }
  }
}
</style>