<template>
  <div class="users">
    <h3>Users <span @click="addUser()"></span></h3>
    <div class="user_list">
      <div class="list_header">
        <div>email</div>
        <div>Last Name</div>
        <div>First Name</div>
        <div>Groups</div>
        <div>Edit</div>
      </div>
      <div class="list_body" v-for="(user,index) in users" :key="index">
        <div>{{ user.email }}</div>
        <div>{{ user.firstName }}</div>
        <div>{{ user.lastName }}</div>
        <div>
          <transition v-for="groupId in user.groups">
            <div v-if="groups[groupId] !== undefined">{{ groups[groupId].name }}</div>
          </transition>
        </div>
        <div><span class="edit" @click="editUser(index)"></span></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, toRefs, watch } from 'vue';
let props = defineProps({ users: Object, groups: Object });
const { users, groups } = toRefs(props);
const emit = defineEmits(['selected','addUser']);
function editUser(i) {
  emit('selected',i);
}
function addUser(i) {
  emit('addUser',i);
}

watch(() => props.users, (newValue) =>
{
  users.value = newValue;
}, { deep: true });

watch(() => props.groups, (newValue) =>
{
  groups.value = newValue;
}, { deep: true });
</script>

<style lang="scss" scoped>
  .users{
    .user_list{
      padding: 20px 20px 0 20px;
      .list_header{
        font-weight: 500;
      }
      >div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #444;
        border-bottom: 1px solid #bcbcbc;
        >div{
          padding: 8px 0;
          display: flex;
          align-items: center;
        }
        >div:nth-child(1){
          width: 25%;
          word-break: break-word;
        }
        >div:nth-child(2){
          width: 15%;
        }
        >div:nth-child(3){
          width: 15%;
        }
        >div:nth-child(4){
          width: 38%;
          display: flex;
          flex-wrap: wrap;
          >div{
            height: 37px;
            line-height: 35px;
            border: 1px solid #bcbcbc;
            border-radius: 8px;
            padding: 0 16px;
            background-color: #f2f2f2;
            font-size: 1rem;
            margin: 2px;
            box-sizing: border-box;
          }
        }
        >div:nth-child(5){
          width: 7%;
          justify-content: center;
          .edit{
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            background-image: url(../../../public/img/edit.svg);
            cursor: pointer;
          }
        }
      }
    }
    h3{
      height: 60px;
      line-height: 60px;
      margin: 0px;
      padding: 0 0 0 20px;
      background-color: #f2f2f2;
      position: relative;
      span{
        height: 30px;
        width: 30px;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: center;
        background-image: url(../../../public/img/plus_gray.svg);
        cursor: pointer;
      }
    }
  }
</style>